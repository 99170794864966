import React from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import AuthRoute from './AuthRoute'
import SitesPage from './pages/Sites'
import SitePage from './site/SitePage'
import LinkSites from './pages/LinkSites'
import IntakeForm from './pages/IntakeForm'
import NotFound from './pages/NotFound'
import MySites from './pages/MySites'
import UnassignedSites from './pages/UnassignedSites'
import QASites from './pages/QASites'
import CreateDudaProject from './create_duda/CreateDudaProject'
import { isAdmin, isSDR } from '../utils/users'
import LaunchSchedulePage from './pages/LaunchSchedulePage'
import CoachingSessions from './pages/CoachingSessions'
import SitePagesTable from '../utils/site pages table/SitePagesTable'
import SeoPage from './pages/SeoPage'
import ScrapePage from './pages/ScrapePage'
import goDaddyTools from './goDaddyTools'

function MainRoutes() {
  
  const { pathname } = useLocation()

  return (
    <Switch>
      
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      <Route exact path="/">
        <Redirect to="/sites" />
      </Route>

      <AuthRoute path='/sites' exact component={SitesPage} />
      <AuthRoute path='/sites/id/:id' component={SitePage} />
      <AuthRoute path='/sites/me' exact component={MySites} auth={!isAdmin()} />
      <AuthRoute path='/sites/unassigned' exact component={UnassignedSites} />
      <AuthRoute path='/sites/qa' exact component={QASites} />
      <AuthRoute path='/sites/seo' exact component={SeoPage} />
      <AuthRoute path='/sites/coaching-sessions' exact component={CoachingSessions} />
      <AuthRoute path='/pages/:id' component={SitePagesTable} />
      <AuthRoute path='/create' exact component={CreateDudaProject} />
      <AuthRoute path='/link' exact component={LinkSites} />
      <AuthRoute path='/launch-schedule' exact component={LaunchSchedulePage} />
      <AuthRoute path='/scrapes' exact component={ScrapePage} />
      <AuthRoute path='/intake' exact component={IntakeForm} auth={!isSDR()}/>
      <AuthRoute path='/godaddy-tools' exact component={goDaddyTools} />

      <Route component={NotFound} />

    </Switch>
  )
}

export default MainRoutes
