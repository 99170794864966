import SidebarPage from "../SidebarPage";
import FH from "../intake/FH";
import { useState, useContext } from "react";
import IntakeSteps from "../intake/IntakeSteps";
import IntakeNav from "../intake/IntakeNav";
import IntakeTemplate from "../intake/IntakeTemplate";
import Design from "../intake/Design";
import AdditionalDetails from "../intake/AdditionalDetails";
import axiosConfig from '../../axiosConfig';
import IntakeLoader from "../intake/IntakeLoader";
import { ModalContext } from "../../hooks/ModalContext";
import logActivity from "../../utils/logActivity";

const IntakeForm = () => {

  const intakeSteps = [
    { id: 'fh', name: 'Funeral Home', complete: false },
    { id: 'template', name: 'Template', complete: false },
    { id: 'design', name: 'Design', complete: false },
    { id: 'additional', name: 'Additional Info', complete: false },
  ]

  let [allSteps, setAllSteps] = useState(intakeSteps);
  let [currentStep, setCurrentStep] = useState(intakeSteps[0]);

  let { handleModal } = useContext(ModalContext);

  const setCurrentStepById = (stepId) => {
    setCurrentStep(allSteps.find(step => step.id == stepId));
  }

  let [fh, setFh] = useState({
    details: {
      name: '',
      provider: '',
      corporation: '',
      referralPartner: '',
      fundingPartner: '',
      url: '',
    },
    contact: {
      name: '',
      phone: '',
      email: ''
    },
    locations: [],
    hostedObits: false,
    urgentBuild: false,
    urgentBuildDesc: ''
  })

  let [template, setTemplate] = useState({
    id: undefined,
    name: undefined
  })

  let [content, setContent] = useState({});

  let [design, setDesign] = useState({
    notes: ''
  });

  let [additionalInfo, setAdditionalInfo] = useState({
    additional: '',
    ae: '',
    aeName: '',
    am: '',
    amName: '',
    florist: '',
    floristName: '',
    sdr: '',
    sdrName: '',
    designer: '',
    passare: false,
    crakn: false,
    halcyon: false,
    osiris: false,
    asd: false,
    ga: false,
    gtm: false,
    lp: false,
    memoriams: false,
    swl: false,
    aftercare: false,
    aftercareAccountId: '',
    asdMailbox: '',
    asdPassword: '',
    activeSync: false,
    autoPublish: false,
    passareApiKey: '',
    obituaryNotifications: 'Instant',
    ownsDomain: false,
    dnsProvider: '',
    dnsUsername: '',
    dnsPassword: '',
    dnsNotes: '',
    shop: []
  })


  const completeStep = (stepId, completeStatus = true, details = undefined) => {
    let newSteps = [...allSteps];
    let updateIdx = newSteps.findIndex(step => step.id == stepId);
    let stepToUpdate = newSteps.find(step => step.id == stepId);
    stepToUpdate.complete = completeStatus;
    if (details) stepToUpdate.details = details;
    newSteps[updateIdx] = stepToUpdate;
    setAllSteps(newSteps);
  }

  const setStepDetails = (stepId, details) => {
    let newSteps = [...allSteps];
    let updateIdx = newSteps.findIndex(step => step.id == stepId);
    let stepToUpdate = newSteps.find(step => step.id == stepId);
    stepToUpdate.details = details;
    newSteps[updateIdx] = stepToUpdate;
    setAllSteps(newSteps);
  }

  const validate = {
    fh: () => {

      let invalid = [];

      Object.keys(fh.details).forEach(property => {
        if (fh.details[property] == '') invalid.push(property);
      });
      Object.keys(fh.contact).forEach(property => {
        if (fh.contact[property] == '') invalid.push(`contact ${property}`);
      });
      if (fh.locations.length == 0) invalid.push('locations (at least one)');
      if (fh.urgentBuild === true && !fh.urgentBuildDesc) invalid.push('urgent build reasoning')

      return invalid.length === 0 ? { isValid: true, detailMessage: fh.details.name, hostedObits: fh.hostedObits } : { isValid: false, errors: invalid };

    },

    template: () => {
      return template.id !== undefined && template.name !== undefined
        ? { isValid: true, detailMessage: template.name }
        : { isValid: false, errors: ['template'] };
    },

    additional: () => {
      let invalid = [];
      if (additionalInfo.ae == '') invalid.push('Account Executive')
      if (additionalInfo.sdr == '') invalid.push('SDR')
      if (additionalInfo.florist == '') invalid.push('Floral Specialist')
      if (additionalInfo.am == '') invalid.push('Account Manager')
      return invalid.length === 0 ? { isValid: true } : { isValid: false, errors: invalid };
    }

  }

  const createSiteProcess = () => {
    handleModal(<IntakeLoader />);

    let site_images = [];

    if (content.site_images) {
      content.site_images.map((selectedContentItem) => {
        if (selectedContentItem.file || selectedContentItem.comment) {
          site_images.push(selectedContentItem);
        }
      });
    }

    let data = {
      fh: fh,
      template: template,
      floral: {
        shops: additionalInfo.shop
      },
      notes: {
        design: design.notes,
        additional: additionalInfo.additional
      },
      dnsInfo: {
        ownsDomain: additionalInfo.ownsDomain,
        dnsProvider: additionalInfo.dnsProvider,
        dnsUsername: additionalInfo.dnsUsername,
        dnsPassword: additionalInfo.dnsPassword,
        dnsOwner: additionalInfo.dnsOwner,
        dnsNotes: additionalInfo.dnsNotes
      },
      integrations: {
        passare: additionalInfo.passare,
        crakn: additionalInfo.crakn,
        halcyon: additionalInfo.halcyon,
        osiris: additionalInfo.osiris,
        activeSync: additionalInfo.activeSync,
        autoPublish: additionalInfo.autoPublish,
        passareApiKey: additionalInfo.passareApiKey,
        asd: additionalInfo.asd,
        asdMailbox: additionalInfo.asdMailbox,
        asdPassword: additionalInfo.asdPassword,
        ga: additionalInfo.ga,
        gaContainerId: additionalInfo.gaContainerId,
        gtm: additionalInfo.gtm,
        gtmContainerId: additionalInfo.gtmContainerId,
        lp: additionalInfo.lp,
        memoriams: additionalInfo.memoriams,
        swl: additionalInfo.swl,
        tas: additionalInfo.tas,
        aftercare: additionalInfo.aftercare,
        aftercareAccountId: additionalInfo.aftercareAccountId
      },
      subscriberInfo: {
        obituaryNotifications: additionalInfo.obituaryNotifications
      },
      team: {
        designer: additionalInfo.designer,
        ae: additionalInfo.ae == 0 ? "" : additionalInfo.ae,
        aeName: additionalInfo.aeName == "none" ? "" : additionalInfo.aeName,
        florist: additionalInfo.florist == 0 ? "" : additionalInfo.florist,
        floristName: additionalInfo.floristName == "none" ? "" : additionalInfo.floristName,
        sdr: additionalInfo.sdr == 0 ? "" : additionalInfo.sdr,
        sdrName: additionalInfo.sdrName == "none" ? "" : additionalInfo.sdrName,
        am: additionalInfo.am == 0 ? "" : additionalInfo.am,
        amName: additionalInfo.amName == "none" ? "" : additionalInfo.amName
      }
    }  

    axiosConfig.post('/api/sites/intake', data)
      .then((res) => {

        let fhid = res.data;
        data.fh['id'] = fhid;
        let thingsToDo = [];

        site_images.map((image) => {

          let formdata = new FormData();

          formdata.append("fhid", fhid);
          formdata.append("label", image.label);
          formdata.append("alt", image.alt);
          if (image.file) {
            formdata.append("upload_file", image.file);
          }
          if (image.comment) {
            formdata.append("comment", image.comment);
          }

          thingsToDo.push(axiosConfig.post(`api/sites/upload`, formdata, { withCredentials: true, 'content-type': 'multipart/form-data', 'maxContentLength': Infinity, 'maxBodyLength': Infinity }));
        })

        Promise.all(thingsToDo)
          .catch((err) => {
            handleModal(false)
            console.log(err)
          })
          .finally(() => {
            axiosConfig.post(`/api/sites/createTrelloCard`, data)
              .then((res) => {
                logActivity({
                  message: 'Intake form submitted',
                  fhId: fhid,
                  type: 'INTAKE'
                })

                handleModal(false)
                window.location.href = res.data.cardUrl;
              })
              .catch((err) => {
                handleModal(false)
                console.log(err)
              })
          })
      })
      .catch((err) => {
        console.log(err);
      })

  }

  const submitForm = () => {

    if (validate[currentStep.id]) {

      let validation = validate[currentStep.id]();
      if (validation.isValid === true) {
        createSiteProcess();
      } else {
        let errorMessage = `Please fill out these fields: ${validation.errors.join(', ')}`;
        alert(errorMessage);
      }

    } else {
      createSiteProcess();
    }

  }

  return (
    <SidebarPage docTitle='Create Site | Tukios Website Admin' maxWidth={true}>

      <IntakeSteps
        allSteps={allSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />

      <FH
        currentStep={currentStep}
        fh={fh}
        setFh={setFh}
        completeStep={completeStep}
      />

      <IntakeTemplate
        currentStep={currentStep}
        setCurrentStepById={setCurrentStepById}
        design={design}
        setTemplate={setTemplate}
        completeStep={completeStep}
      />

      <Design
        design={design}
        setDesign={setDesign}
        currentStep={currentStep}
        content={content}
        setContent={setContent}
        template={template}
      />

      <AdditionalDetails
        additionalInfo={additionalInfo}
        setAdditionalInfo={setAdditionalInfo}
        currentStep={currentStep}
      />

      <IntakeNav
        allSteps={allSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        validate={validate}
        completeStep={completeStep}
        submitForm={submitForm}
      />

    </SidebarPage>
  )

}

export default IntakeForm;