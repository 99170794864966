import { AnnotationIcon, UserGroupIcon, AdjustmentsIcon, BellIcon, ServerIcon, PlusCircleIcon } from "@heroicons/react/solid";
import { useEffect, useState, useContext } from "react";
import axiosConfig from '../../axiosConfig';
import ToggleWithLabel from '../ui/ToggleWithLabel'
import { Switch } from '@headlessui/react'
import { ModalContext } from "../../hooks/ModalContext";
import AddFloralLocationDialog from "../ui/modal/dialogs/AddFloralLocation";
import { listAes, listAms, listFlorists, listSdrs } from "../../utils/utilityLibrary";

const AdditionalDetails = ({ currentStep, additionalInfo, setAdditionalInfo }) => {

  let [aes, setAes] = useState([]);
  let [florists, setFlorists] = useState([]);
  let [sdrs, setSdrs] = useState([]);
  let [ams, setAms] = useState([]);
  let { handleModal } = useContext(ModalContext);
  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const addFloralShop = (e) => {
    if (e.currentTarget.value == '') {
      handleModal(
        <AddFloralLocationDialog
          newShop={newShop}
          editShop={false}
          existingShop={false}
          index={false}
        />
      )
    }
    else {
      handleModal(
        <AddFloralLocationDialog
          newShop={false}
          editShop={editShop}
          existingShop={additionalInfo.shop[e.currentTarget.value]}
          index={e.currentTarget.value}
        />
      )
    }
  }

  const newShop = (newAdditional) => {
    let newAdditionalInfo = { ...additionalInfo };
    additionalInfo.shop.push(newAdditional);
    setAdditionalInfo(newAdditionalInfo);
  }

  const editShop = (shop, index) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.shop[index] = shop;
    setAdditionalInfo(newAdditionalInfo);
  }


  const handleRadioButton = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      [e.target.name]: e.target.id
    })
  }

  const handleChange = (e) => {
    if (e.target.classList.contains("tukios-checkbox")) {
      if (e.target.name === "activeSync" && e.target.checked == false) {
        setAdditionalInfo({
          ...additionalInfo['autoPublish'] = false
        })
      }
      setAdditionalInfo({
        ...additionalInfo,
        [e.target.name]: e.target.checked
      })
    }
    else {
      if (e.target.name === 'ae') {
        setAdditionalInfo({
          ...additionalInfo,
          [e.target.name]: e.target.value,
          ['aeName']: e.target.options[e.target.selectedIndex].getAttribute('data-name')
        })
      }
      else if (e.target.name === 'florist') {
        setAdditionalInfo({
          ...additionalInfo,
          [e.target.name]: e.target.value,
          ['floristName']: e.target.options[e.target.selectedIndex].getAttribute('data-name')
        })
      }
      else if (e.target.name === 'sdr') {
        setAdditionalInfo({
          ...additionalInfo,
          [e.target.name]: e.target.value,
          ['sdrName']: e.target.options[e.target.selectedIndex].getAttribute('data-name')
        })
      }
      else if (e.target.name === 'am') {
        setAdditionalInfo({
          ...additionalInfo,
          [e.target.name]: e.target.value,
          ['amName']: e.target.options[e.target.selectedIndex].getAttribute('data-name')
        })
      }
      else {
        setAdditionalInfo({
          ...additionalInfo,
          [e.target.name]: e.target.value
        })
      }
    }
  }

  const setOwnsDomain = (e) => {
    if (e == false) {
      setAdditionalInfo({
        ...(additionalInfo["dnsProvider"] = ""),
        ...(additionalInfo["dnsUsername"] = ""),
        ...(additionalInfo["dnsPassword"] = ""),
      });
    }
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.ownsDomain = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setPassare = (e) => {
    if (e == false) {
      setAdditionalInfo({
        ...(additionalInfo["autoPublish"] = false),
        ...(additionalInfo["activeSync"] = false),
        ...(additionalInfo["passareApiKey"] = ""),
      });
    }
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.passare = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setCrakn = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.crakn = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setHalcyon = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.halcyon = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setOsiris = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.osiris = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setAsd = (e) => {
    if (e == false) {
      setAdditionalInfo({
        ...additionalInfo['asdMailbox'] = '',
        ...additionalInfo['asdPassword'] = '',
      })
    }
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.asd = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setGa = (e) => {
    if (e == false) {
      setAdditionalInfo({
        ...additionalInfo['gaContainerId'] = ''
      })
    }
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.ga = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setGtm = (e) => {
    setAdditionalInfo({
      ...additionalInfo['gtmContainerId'] = ''
    })
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.gtm = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setLp = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.lp = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setMemoriams = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.memoriams = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setAftercare = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.aftercare = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setSwl = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.swl = e;
    setAdditionalInfo(newAdditionalInfo);
  }

  const setTas = (e) => {
    let newAdditionalInfo = { ...additionalInfo };
    newAdditionalInfo.tas = e;
    setAdditionalInfo(newAdditionalInfo);
  }


  useEffect(() => {
    Promise.all([listSdrs(), listAes(), listAms(), listFlorists()])
      .then(([sdrs, aes, ams, florists]) => {
        setSdrs(sdrs)
        setAes(aes)
        setAms(ams)
        setFlorists(florists)
      })
  }, []);

  if (currentStep.id !== 'additional') return null;
  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="bg-white p-5 space-y-4 shadow rounded">
          <div className="text-lg font-medium flex items-center">
            <UserGroupIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>Team Members</div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="ae"
                className="block text-sm font-medium text-gray-700"
              >
                Account Executive
              </label>
              <select
                name="ae"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                value={additionalInfo.ae}
                onChange={handleChange}
              >
                {/* <option disabled value="chooseProvider">Choose one</option> */}
                <option value="" disabled>
                  Select one
                </option>
                <option key={0} data-name={'none'} value={0}>
                  None
                </option>
                {aes.map((ae) => (
                  <option key={ae.id} data-name={ae.name} value={ae.user_id}>
                    {ae.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="ae"
                className="block text-sm font-medium text-gray-700"
              >
                Lead Source
              </label>
              <select
                name="sdr"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                value={additionalInfo.sdr}
                onChange={handleChange}
              >
                {/* <option disabled value="chooseProvider">Choose one</option> */}
                <option value="" disabled>
                  Select one
                </option>
                <option key={0} data-name={'none'} value={0}>
                  None
                </option>
                {
                  sdrs.map((sdr) => (
                    <option key={sdr.id} data-name={sdr.name} value={sdr.user_id}>
                      {sdr.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="florist"
                className="block text-sm font-medium text-gray-700"
              >
                Floral Specialist
              </label>
              <select
                name="florist"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                value={additionalInfo.florist}
                onChange={handleChange}
              >
                {/* <option disabled value="chooseProvider">Choose one</option> */}
                <option value="" disabled>
                  Select one
                </option>
                <option key={0} data-name={'none'} value={0}>
                  None
                </option>
                {florists.map((florist) => (
                  <option key={florist.id} data-name={florist.name} value={florist.user_id}>
                    {florist.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-3">
              <label
                htmlFor="am"
                className="block text-sm font-medium text-gray-700"
              >
                Account Manager
              </label>
              <select
                name="am"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                value={additionalInfo.am}
                onChange={handleChange}
              >
                {/* <option disabled value="chooseProvider">Choose one</option> */}
                <option value="" disabled>
                  Select one
                </option>
                <option key={0} data-name={'none'} value={0}>
                  None
                </option>
                {ams.map((am) => (
                  <option key={am.id} data-name={am.name} value={am.user_id}>
                    {am.name}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="sm:col-span-3">
            <label htmlFor="designer" className="block text-sm font-medium text-gray-700">
              Designer <span className="text-gray-500 text-sm font-normal">- optional</span>
            </label>
            <select
              name="designer"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
              value={additionalInfo.designer}
              onChange={handleChange}
            >
              <option value="" disabled>Select one</option>
              {
                designers.map(designer => (
                  <option key={designer.user_id} value={designer.user_id}>{designer.name}</option>
                ))
              }
            </select>
          </div> */}
          </div>
        </div>

        <div className="bg-white p-5 space-y-4 shadow rounded">
          <div className="text-lg font-medium flex items-center">
            <ServerIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>DNS Info</div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <ToggleWithLabel
                enabled={additionalInfo.ownsDomain}
                setEnabled={setOwnsDomain}
                label="Does the funeral home own their domain?"
              />
            </div>
          </div>
          {additionalInfo.ownsDomain ? (
            <div className="flex-row mt-5">
              <label
                htmlFor="dnsProvider"
                className="block text-sm font-medium text-gray-700"
              >
                DNS Provider
              </label>
              <div className="mt-1">
                <input
                  name="dnsProvider"
                  type="text"
                  placeholder="GoDaddy"
                  onChange={handleChange}
                  value={additionalInfo.dnsProvider}
                  className={inputClasses}
                  label="dnsProvider"
                />
              </div>
              <label
                htmlFor="dnsUsername"
                className="block text-sm font-medium text-gray-700 mt-3"
              >
                Username / Email
              </label>
              <div className="mt-1">
                <input
                  name="dnsUsername"
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  onChange={handleChange}
                  value={additionalInfo.dnsUsername}
                  className={inputClasses}
                  label="dnsUsername"
                />
              </div>
              <label
                htmlFor="dnsPassword"
                className="block text-sm font-medium text-gray-700 mt-3"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  name="dnsPassword"
                  type="text"
                  placeholder="Cremation123!"
                  onChange={handleChange}
                  value={additionalInfo.dnsPassword}
                  className={inputClasses}
                  label="dnsPassword"
                />
              </div>
            </div>
          ) : (
            <div className="flex-row mt-5">
              <label
                htmlFor="dnsOwner"
                className="block text-sm font-medium text-gray-700"
              >
                Domain Owner
              </label>
              <div className="mt-1">
                <input
                  name="dnsOwner"
                  type="text"
                  placeholder="CFS"
                  onChange={handleChange}
                  value={additionalInfo.dnsOwner}
                  className={inputClasses}
                  label="dnsOwner"
                />
              </div>
            </div>
          )}
          <div className="mt-1">
            <label htmlFor="dnsNotes" className="block text-sm font-medium text-gray-700">
              Notes
            </label>
            <div className="mt-1">
              <textarea
                name="dnsNotes"
                type="text"
                placeholder="Anything else the team should know?"
                onChange={handleChange}
                value={additionalInfo.dnsNotes}
                className={inputClasses}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded divide-y">
          <div className="text-lg font-medium flex items-center sm:col-span-6 p-5">
            <AdjustmentsIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>Integrations</div>
          </div>

          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <img className="h-8" src="/images/passare-logo.png" />
              <Switch
                checked={additionalInfo.passare}
                onChange={setPassare}
                className={classNames(
                  additionalInfo.passare ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.passare ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>

            {additionalInfo.passare && (
              <fieldset className="space-y-5 grid grid-cols-2">
                <legend className="sr-only">Passare</legend>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="activeSync"
                      aria-describedby="comments-description"
                      name="activeSync"
                      type="checkbox"
                      onChange={handleChange}
                      checked={additionalInfo.activeSync}
                      className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300 rounded tukios-checkbox"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="activeSync"
                      className="font-medium text-gray-700"
                    >
                      Active Sync
                    </label>
                    <p id="candidates-description" className="text-gray-500">
                      Import obituaries and updates from Passare, if this option
                      is not selected new changes in Passare will not come over (one time import)
                    </p>
                  </div>
                </div>
                <div className="relative flex items-start">
                  <div className="flex items-center h-5">
                    <input
                      id="autoPublish"
                      name="autoPublish"
                      type="checkbox"
                      disabled={!additionalInfo.activeSync}
                      onChange={handleChange}
                      checked={additionalInfo.autoPublish}
                      className={classNames(
                        !additionalInfo.activeSync
                          ? "opacity-30"
                          : "opacity-100",
                        "focus:ring-tukios h-4 w-4 text-tukios border-gray-300 rounded tukios-checkbox"
                      )}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="autoPublish"
                      className="font-medium text-gray-700"
                    >
                      Auto Publish
                    </label>
                    <p id="candidates-description" className="text-gray-500">
                      Imported obituaries from Passare will be published
                      immediantely
                    </p>
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="passareApiKey"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Passare API Key{" "}
                    <span className="text-gray-500 text-sm font-normal">
                      - Optional
                    </span>
                  </label>

                  <div className="mt-1">
                    <input
                      name="passareApiKey"
                      type="text"
                      onChange={handleChange}
                      value={additionalInfo.passareApiKey}
                      className={inputClasses}
                      label="passareApiKey"
                    />
                  </div>
                </div>
              </fieldset>
            )}
          </div>

          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/crakn-logo.png" />
                {/* <label className="text-sm font-medium text-gray-700">
                  (Coming Soon)
                </label> */}
              </div>
              <Switch
                checked={additionalInfo.crakn}
                onChange={setCrakn}
                className={classNames(
                  additionalInfo.crakn ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.crakn ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>

          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/halcyon-logo.png" />
                {/* <label className="text-sm font-medium text-gray-700">
                  (Coming Soon)
                </label> */}
              </div>
              <Switch
                checked={additionalInfo.halcyon}
                onChange={setHalcyon}
                className={classNames(
                  additionalInfo.halcyon ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.halcyon ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>

          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/osiris-logo.png" />
                {/* <label className="text-sm font-medium text-gray-700">
                  (Coming Soon)
                </label> */}
              </div>
              <Switch
                checked={additionalInfo.osiris}
                onChange={setOsiris}
                className={classNames(
                  additionalInfo.osiris ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.osiris ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>

          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <img className="h-8" src="/images/asd-logo.svg" />
              <Switch
                checked={additionalInfo.asd}
                onChange={setAsd}
                className={classNames(
                  additionalInfo.asd ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.asd ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>

            {additionalInfo.asd && (
              <div className="flex-row mt-5">
                <label
                  htmlFor="asdMailbox"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mailbox
                </label>
                <div className="mt-1">
                  <input
                    name="asdMailbox"
                    type="text"
                    onChange={handleChange}
                    value={additionalInfo.asdMailbox}
                    className={inputClasses}
                    label="asdMailbox"
                  />
                </div>
                <label
                  htmlFor="asdPassword"
                  className="block text-sm font-medium text-gray-700 mt-3"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    name="asdPassword"
                    type="text"
                    onChange={handleChange}
                    value={additionalInfo.asdPassword}
                    className={inputClasses}
                    label="asdPassword"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <img className="h-8" src="/images/google-analytics-logo.svg" />
              <Switch
                checked={additionalInfo.ga}
                onChange={setGa}
                className={classNames(
                  additionalInfo.ga ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.ga ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
            {additionalInfo.ga && (
              <div className="flex-row mt-5">
                <label
                  htmlFor="gaContainerId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Container ID
                </label>
                <div className="mt-1">
                  <input
                    name="gaContainerId"
                    type="text"
                    onChange={handleChange}
                    value={additionalInfo.gaContainerId}
                    className={inputClasses}
                    label="gaContainerId"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <img className="h-8" src="/images/google-tag-manager-logo.svg" />
              <Switch
                checked={additionalInfo.gtm}
                onChange={setGtm}
                className={classNames(
                  additionalInfo.gtm ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.gtm ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
            {additionalInfo.gtm && (
              <div className="flex-row mt-5">
                <label
                  htmlFor="gtmContainerId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Container ID
                </label>
                <div className="mt-1">
                  <input
                    name="gtmContainerId"
                    type="text"
                    onChange={handleChange}
                    value={additionalInfo.gtmContainerId}
                    className={inputClasses}
                    label="gtmContainerId"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/legacy-logo.png" />
                <label className="text-sm font-medium text-gray-700">
                  (Legacy Pro)
                </label>
              </div>
              <Switch
                checked={additionalInfo.lp}
                onChange={setLp}
                className={classNames(
                  additionalInfo.lp ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.lp ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/memoriams-logo.png" />
              </div>
              <Switch
                checked={additionalInfo.memoriams}
                onChange={setMemoriams}
                className={classNames(
                  additionalInfo.memoriams ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.memoriams ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/aftercare-logo.png" />
              </div>
              <Switch
                checked={additionalInfo.aftercare}
                onChange={setAftercare}
                className={classNames(
                  additionalInfo.aftercare ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.aftercare ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
            {additionalInfo.aftercare && (
              <div className="flex-row mt-5">
                <label
                  htmlFor="aftercareAccountId"
                  className="block text-sm font-medium text-gray-700"
                >
                  Aftercare Account ID
                </label>
                <div className="mt-1">
                  <input
                    name="aftercareAccountId"
                    type="text"
                    onChange={handleChange}
                    value={additionalInfo.aftercareAccountId}
                    className={inputClasses}
                    label="aftercareAccountId"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/swl-logo.webp" />
              </div>
              <Switch
                checked={additionalInfo.swl}
                onChange={setSwl}
                className={classNames(
                  additionalInfo.swl ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.swl ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
          <div className="p-5">
            <div className="flex items-center flex-wrap justify-between">
              <div className="flex">
                <img className="h-8" src="/images/tukios.png" />
                <label className="text-sm font-medium text-gray-700">
                  (Tukios Arrangement Software)
                </label>
              </div>
              <Switch
                checked={additionalInfo.tas}
                onChange={setTas}
                className={classNames(
                  additionalInfo.tas ? "bg-tukios-green" : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    additionalInfo.tas ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>

        <div className="bg-white p-5 space-y-4 shadow rounded">
          <div className="text-lg font-medium flex items-center">
            <BellIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>Subscriber Settings</div>
          </div>
          <div
            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
            id="label-notifications"
          >
            Obituary Notifications
          </div>
          <div className="mt-4 space-y-4">
            <div className="flex items-center">
              <input
                id="Instant"
                name="obituaryNotifications"
                type="radio"
                defaultChecked="checked"
                onClick={handleRadioButton}
                className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
              />
              <div>
                <label
                  htmlFor="Instant"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Instant
                </label>
                <label
                  htmlFor="Instant"
                  className="ml-3 block text-sm text-gray-500"
                >
                  Individual notifications are sent for each obituary
                </label>
              </div>
            </div>
            <div className="flex items-center">
              <input
                id="Digest"
                onClick={handleRadioButton}
                name="obituaryNotifications"
                type="radio"
                className="focus:ring-tukios h-4 w-4 text-tukios border-gray-300"
              />
              <div>
                <label
                  htmlFor="Digest"
                  className="ml-3 block text-sm font-medium text-gray-700"
                >
                  Digest
                </label>
                <label
                  htmlFor="Digest"
                  className="ml-3 block text-sm text-gray-500"
                >
                  Obituaries are grouped into daily digest notifications (best option for large firms)
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-5 space-y-4 shadow rounded">
          <div className="text-lg font-medium flex items-center">
            <AnnotationIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>
              Flower Shop{additionalInfo.shop.length > 1 ? 's' : ''}{" "}
              <span className="text-gray-500 text-sm font-normal">
                - Optional
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
            {additionalInfo.shop.map((shop) => (
              <button
                key={additionalInfo.shop.indexOf(shop)}
                value={additionalInfo.shop.indexOf(shop)}
                type="button"
                onClick={addFloralShop}
                className="relative flex flex-col justify-center w-full border-2 hover:border-gray-400 border-gray-300 rounded-lg p-4"
              >
                <h3 className="text-lg underline mb-2">{shop.preference + ") " + shop.name}</h3>
                <span className="mb-2">
                  {shop.address ?
                    `${shop.address ? shop.address : ''}`
                    : ''}
                  {shop.address ? <br /> : ''}
                  {shop.city ? shop.city + ', ' : ''}
                  {shop.state ? shop.state + ' ' : ''}
                  {shop.zip ? shop.zip : ''}
                </span>
                {shop.contactsName && <span>{shop.contactsName}</span>}
                {shop.email && <span>{shop.email}</span>}
                {shop.phone && <span>{shop.phone}</span>}
              </button>
            ))}

            <button
              type="button"
              onClick={addFloralShop}
              className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none"
            >
              <PlusCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
              <span className="mt-2 block text-sm font-medium text-gray-900">
                {additionalInfo.shop.length > 0
                  ? "Add another floral location"
                  : "Add a floral location"}
              </span>
            </button>
          </div>
        </div>

        <div className="bg-white p-5 space-y-4 shadow rounded">
          <div className="text-lg font-medium flex items-center">
            <AnnotationIcon className="h-5 w-5 mb-0.5 mr-1 text-tukios-navy" />
            <div>
              Additional Notes{" "}
              <span className="text-gray-500 text-sm font-normal">
                - Optional
              </span>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label> */}
              <div>
                <textarea
                  name="additional"
                  type="text"
                  placeholder="Anything else the team should know?"
                  onChange={handleChange}
                  value={additionalInfo.additional}
                  className={inputClasses}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AdditionalDetails;
